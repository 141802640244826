import { FaApple, FaEye, FaFacebookF, FaGooglePlusG } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'
import Image from '../../../../assets/images'

const Wrapper = tw.div`max-w-md mx-auto h-full`
const Content = tw.div`w-full h-full p-7`
const LogoWrapper = tw.div`width[85px] height[85px] mt-12 mx-auto`
const Logo = tw.img`w-full h-full object-contain`

const Title = tw.div`text-lg mt-6 font-bold text-center`
const Description = tw.div`text-sm font-normal mt-4 text-center`
const DescriptionHighlight = tw.span`text-orange-1 cursor-pointer`

const SocialMediaSection = tw.div`flex space-x-1 my-6 justify-center`
const SocialMedia = tw.div`w-11 h-11 rounded-full text-white-1 flex items-center justify-center`

const DividerSection = tw.div`flex w-full items-center mb-6`
const Divider = tw.div`h-0.5 flex-1 bg-white-3`
const DividerText = tw.div`mx-2 text-sm text-grey-75`

const InputWrapper = tw.div`flex flex-col w-full mb-4`
const Label = tw.label`mb-3 ml-2.5 font-normal text-black-1`
const Input = tw.input`h-12 bg-white-2 rounded-2xl px-4`
const InputCombine = tw.div`w-full h-12 bg-white-2 rounded-2xl relative`
const InputIcon = tw.div`absolute right-4 top-0 bottom-0 flex justify-center items-center`

const BottomSection = tw.div`py-4`
const BottomWrapper = tw.div`flex justify-between w-full text-grey-75 text-sm font-normal mb-12`
const CheckboxWrapper = tw.div`flex items-center`
const Checkbox = tw.input`mr-2`
const Text = tw.div``
const LoginButton = tw.button`h-16 w-full bg-orange-1 flex items-center justify-center rounded-full text-white-1 font-bold`

const Login = () => {
  const navigate = useNavigate()
  return (
    <Wrapper>
      <Content>
        <LogoWrapper>
          <Logo alt="logo" title="logo" src={Image.logo} />
        </LogoWrapper>

        <Title>Login into daridasar.com</Title>
        <Description>
          Don't have an account?{' '}
          <DescriptionHighlight
            onClick={() => {
              navigate('/register')
            }}
          >
            Signup
          </DescriptionHighlight>
        </Description>

        <SocialMediaSection>
          <SocialMedia css={tw`bg-blue-700`}>
            <FaFacebookF />
          </SocialMedia>

          <SocialMedia css={tw`bg-red-1`}>
            <FaGooglePlusG />
          </SocialMedia>

          <SocialMedia css={tw`bg-black-1`}>
            <FaApple />
          </SocialMedia>
        </SocialMediaSection>

        <DividerSection>
          <Divider />
          <DividerText>Or</DividerText>
          <Divider />
        </DividerSection>

        <InputWrapper>
          <Label htmlFor="email">Email</Label>
          <Input placeholder="Email" type="text" id="email" />
        </InputWrapper>

        <InputWrapper>
          <Label htmlFor="password">Password</Label>
          <InputCombine>
            <Input placeholder="Password" type="password" id="password" />
            <InputIcon>
              <FaEye />
            </InputIcon>
          </InputCombine>
        </InputWrapper>

        <BottomSection>
          <BottomWrapper>
            <CheckboxWrapper>
              <Checkbox type="checkbox"></Checkbox>
              <Text>Remember me</Text>
            </CheckboxWrapper>
            <Text>Forgot password</Text>
          </BottomWrapper>

          <LoginButton
            onClick={() => {
              navigate('/products')
            }}
          >
            Login
          </LoginButton>
        </BottomSection>
      </Content>
    </Wrapper>
  )
}

export default Login
