import React from 'react'
import {
  FaCartArrowDown,
  FaChevronRight,
  FaEdit,
  FaHeart,
  FaHome,
  FaLock,
  FaMapPin,
  FaShoppingBag,
  FaSignOutAlt,
  FaUser,
} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'
import Image from '../../../../assets/images/'
const Wrapper = tw.div`max-w-md mx-auto h-full`
const Content = tw.div`w-full h-full p-7`

const BlankSpace = tw.div`h-20`

const BottomBar = tw.div`bg-white-1 text-grey-75 flex flex-row justify-between items-center h-20 rounded-t-3xl fixed bottom-0 left-0 right-0 shadow-inner max-w-md px-7 mx-auto`
const BottomBarItem = tw.div`cursor-pointer w-10 flex items-center justify-center`

const ProfileSection = tw.div`flex flex-row items-center`
const ProfileImageWrapper = tw.div`w-24 h-24 rounded-full bg-grey-E5 mr-4 relative`
const ProfileImage = tw.img`w-full h-full rounded-full object-contain`
const ProfileImageButton = tw.label`flex flex-col items-center absolute right-0 bottom-0 bg-orange-2 p-1.5 rounded text-orange-1 cursor-pointer`
const ProfileImageHidden = tw.input`hidden`
const ProfileDetail = tw.div``
const ProfileName = tw.div`font-bold`
const ProfileEmail = tw.div`text-grey-AA`

const Divider = tw.div`border-t my-6`

const InfoWrapper = tw.div`flex flex-row justify-evenly mb-6`
const Info = tw.div`flex flex-col items-center cursor-pointer`
const InfoTitle = tw.div`text-grey-AA`
const InfoTotal = tw.div`text-xs`

const MenuWrapper = tw.div``
const Menu = tw.div`flex flex-row justify-between items-center cursor-pointer mb-2 rounded`
const MenuContent = tw.div`flex flex-row items-center`
const MenuIcon = tw.div`w-12 h-12 rounded bg-orange-2 flex flex-row items-center justify-center mr-4 text-orange-1`
const MenuTitle = tw.div``

const Profile = () => {
  const navigate = useNavigate()

  const infoList = [
    {
      title: 'Wishlist',
      icon: <FaHeart css={tw`text-orange-1`} />,
      handleClick: () => {
        navigate('/products/wishlist')
      },
      total: 12,
    },
    {
      title: 'Cart',
      icon: <FaShoppingBag css={tw`text-orange-1`} />,
      handleClick: () => {
        navigate('/cart')
      },
      total: 22,
    },
    {
      title: 'Order',
      icon: <FaCartArrowDown css={tw`text-orange-1`} />,
      handleClick: () => {
        navigate('/order')
      },
      total: 9,
    },
  ]

  const menuList = [
    {
      title: 'Edit Profile',
      icon: <FaUser css={tw`text-orange-1`} />,
      handleClick: () => {
        navigate('/profile/edit')
      },
    },
    {
      title: 'Update Password',
      icon: <FaLock css={tw`text-orange-1`} />,
      handleClick: () => {
        navigate('/profile/update-password')
      },
    },
    {
      title: 'Address',
      icon: <FaMapPin css={tw`text-orange-1`} />,
      handleClick: () => {
        navigate('/address')
      },
    },
    {
      title: 'Logout',
      icon: <FaSignOutAlt css={tw`text-orange-1`} />,
      handleClick: () => {
        navigate('/login')
      },
    },
  ]

  return (
    <Wrapper>
      <Content>
        <ProfileSection>
          <ProfileImageWrapper>
            <ProfileImage src={Image.profile} alt="profile" title="profile" />
            <ProfileImageHidden type="file" id="photo" />
            <ProfileImageButton htmlFor="photo">
              <FaEdit />
            </ProfileImageButton>
          </ProfileImageWrapper>
          <ProfileDetail>
            <ProfileName>Angela lierna</ProfileName>
            <ProfileEmail>angelalierna@gmail.com</ProfileEmail>
          </ProfileDetail>
        </ProfileSection>

        <Divider />

        <InfoWrapper>
          {infoList.map((val, idx) => {
            return (
              <Info key={idx} onClick={val.handleClick}>
                {val.icon}
                <InfoTitle>{val.title}</InfoTitle>
                <InfoTotal>{val.total}</InfoTotal>
              </Info>
            )
          })}
        </InfoWrapper>

        <MenuWrapper>
          {menuList.map((val, idx) => {
            return (
              <Menu key={idx} onClick={val.handleClick}>
                <MenuContent>
                  <MenuIcon>{val.icon}</MenuIcon>
                  <MenuTitle>{val.title}</MenuTitle>
                </MenuContent>
                <FaChevronRight css={tw`mr-2`} />
              </Menu>
            )
          })}
        </MenuWrapper>

        <BlankSpace />
      </Content>

      {/* #region Bottom Bar */}

      <BottomBar>
        <BottomBarItem
          onClick={() => {
            navigate('/products')
          }}
        >
          <FaHome css={tw`text-orange-1`} />
        </BottomBarItem>
        <BottomBarItem
          onClick={() => {
            navigate('/products/wishlist')
          }}
        >
          <FaHeart />
        </BottomBarItem>
        <BottomBarItem
          onClick={() => {
            navigate('/order')
          }}
        >
          <FaCartArrowDown />
        </BottomBarItem>
        <BottomBarItem
          onClick={() => {
            navigate('/profile')
          }}
        >
          <FaUser />
        </BottomBarItem>
      </BottomBar>
      {/* #endregion */}
    </Wrapper>
  )
}

export default Profile
