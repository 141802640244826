import React from 'react'
import { FaChevronLeft, FaEye } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'

const Wrapper = tw.div`max-w-md mx-auto h-full`
const Content = tw.div`w-full h-full p-7`

const Navigation = tw.div`flex flex-row justify-between items-center`
const NavigationButton = tw.button``
const NavigationTitle = tw.div``
const Main = tw.div`mt-4`

const InputWrapper = tw.div`flex flex-col w-full mb-4`
const Label = tw.label`mb-3 ml-2.5 font-normal text-black-1`
const Input = tw.input`h-12 bg-white-2 rounded-2xl px-4 w-full`

const BottomSection = tw.div`fixed bottom-0 left-0 right-0 max-w-md px-7 py-4 mx-auto bg-white-1`
const SaveButton = tw.button`h-16 w-full bg-orange-1 flex items-center justify-center rounded-full text-white-1 font-bold`

const BlankSpace = tw.div`h-20`

const InputCombine = tw.div`w-full h-12 bg-white-2 rounded-2xl relative`
const InputIcon = tw.div`absolute right-4 top-0 bottom-0 flex justify-center items-center`

const UpdatePassword = () => {
  const navigate = useNavigate()

  return (
    <Wrapper>
      <Content>
        <Navigation>
          <NavigationButton
            onClick={() => {
              navigate(-1)
            }}
          >
            <FaChevronLeft />
          </NavigationButton>
          <NavigationTitle>Update Password</NavigationTitle>
          <div />
        </Navigation>

        <Main>
          <InputWrapper>
            <Label htmlFor="old_password">Password Lama</Label>
            <InputCombine>
              <Input
                placeholder="Password Lama"
                type="password"
                id="old_password"
              />
              <InputIcon>
                <FaEye />
              </InputIcon>
            </InputCombine>
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="new_password">Password Baru</Label>
            <InputCombine>
              <Input
                placeholder="Password Baru"
                type="password"
                id="new_password"
              />
              <InputIcon>
                <FaEye />
              </InputIcon>
            </InputCombine>
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="confirm_password">Konfirmasi Password</Label>
            <InputCombine>
              <Input
                placeholder="Konfirmasi Password"
                type="password"
                id="confirm_password"
              />
              <InputIcon>
                <FaEye />
              </InputIcon>
            </InputCombine>
          </InputWrapper>
        </Main>
        <BlankSpace />
      </Content>
      <BottomSection>
        <SaveButton
          onClick={() => {
            navigate(-1)
          }}
        >
          Save
        </SaveButton>
      </BottomSection>
    </Wrapper>
  )
}

export default UpdatePassword
