import React from 'react'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'
import Image from '../../../../assets/images'

const Wrapper = tw.div`max-w-md mx-auto h-full`
const Content = tw.div`w-full h-full p-7 flex flex-col items-center justify-center`
const Note = tw.img``
const OrderTitle = tw.div`mt-12 font-extrabold text-[28px]`
const OrderDescription = tw.div`mt-4 text-center px-4 text-grey-75`
const BottomSection = tw.div`fixed bottom-0 left-0 right-0 max-w-md px-7 py-4 mx-auto bg-white-1`
const ContinueButton = tw.button`h-16 w-full bg-orange-1 flex items-center justify-center rounded-full text-white-1 font-bold`

const OrderSuccess = () => {
  const navigate = useNavigate()

  return (
    <Wrapper>
      <Content>
        <Note src={Image.note} alt="note" title="note" />
        <OrderTitle>Order Success</OrderTitle>
        <OrderDescription>
          Your package will be sent to your address, thanks for order.
        </OrderDescription>
      </Content>
      <BottomSection>
        <ContinueButton
          onClick={() => {
            navigate('/products')
          }}
        >
          Back to Home
        </ContinueButton>
      </BottomSection>
    </Wrapper>
  )
}

export default OrderSuccess
