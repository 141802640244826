import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import AddNewAddress from '../module/address/view/AddNewAddress'
import AddressList from '../module/address/view/AddressList'
import Login from '../module/auth/view/Login'
import Register from '../module/auth/view/Register'
import Cart from '../module/order/view/Cart'
import Checkout from '../module/order/view/Checkout'
import Order from '../module/order/view/Order'
import OrderSuccess from '../module/order/view/OrderSuccess'
import PaymentList from '../module/payment/view/PaymentList'
import ProductDetail from '../module/product/view/ProductDetail'
import ProductList from '../module/product/view/ProductList'
import ProductWishList from '../module/product/view/ProductWishList'
import ShippingList from '../module/shipping/view/ShippingList'
import EditProfile from '../module/user/view/EditProfile'
import Profile from '../module/user/view/Profile'
import UpdatePassword from '../module/user/view/UpdatePassword'

const router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/products" element={<ProductList />} />
        <Route path="/products/:id" element={<ProductDetail />} />
        <Route path="/products/wishlist" element={<ProductWishList />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/edit" element={<EditProfile />} />
        <Route path="/profile/update-password" element={<UpdatePassword />} />
        <Route path="/address" element={<AddressList />} />
        <Route path="/address/add" element={<AddNewAddress />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/order-success" element={<OrderSuccess />} />
        <Route path="/order" element={<Order />} />
        <Route path="/payment" element={<PaymentList />} />
        <Route path="/shipping" element={<ShippingList />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
  )
}

export default router
