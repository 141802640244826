import styled from '@emotion/styled'
import React from 'react'
import {
  FaCartArrowDown,
  FaHeart,
  FaHome,
  FaSearch,
  FaUser,
} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'
import Image from '../../../../assets/images'

const Wrapper = tw.div`max-w-md mx-auto h-full`
const Content = tw.div`w-full h-full p-7`
const Header = tw.div`flex flex-row`
const InputWrapper = tw.div`relative w-full h-12 rounded-2xl`
const SearchButton = tw.div`absolute left-4 top-0 bottom-0 flex flex-row items-center text-grey-DB`
const Input = tw.input`w-full h-12 bg-white-2 rounded-2xl px-4 pl-10`

const ProductWrapper = tw.div`grid grid-cols-2 mt-6`
const Product = styled.div(({ extraCss }) => [tw`rounded-2xl mb-4`, extraCss])
const ProductImageWrapper = tw.div`max-h-48 h-full w-full`
const ProductImage = tw.img`w-full h-full object-cover rounded-2xl`
const ProductTitle = tw.div`text-lg`
const ProductPrice = tw.div`text-lg font-bold text-orange-1`

const BottomBar = tw.div`bg-white-1 text-grey-75 flex flex-row justify-between items-center h-20 rounded-t-3xl fixed bottom-0 left-0 right-0 shadow-inner max-w-md px-7 mx-auto`
const BottomBarItem = tw.div`cursor-pointer w-10 flex items-center justify-center`

const Separator = tw.div`h-20`

const ProductWishList = () => {
  const navigate = useNavigate()

  const productList = [
    {
      id: '1',
      title: 'Warm Zipper',
      price: 300,
      img: Image.product1,
    },
    {
      id: '2',
      title: 'Knitterd Wool',
      price: 130,
      img: Image.product2,
    },
    {
      id: '3',
      title: 'Jeans Wool',
      price: 210,
      img: Image.product3,
    },
    {
      id: '4',
      title: 'Knitted Jeans',
      price: 160,
      img: Image.product4,
    },
    {
      id: '5',
      title: 'Warm Wool',
      price: 230,
      img: Image.product5,
    },
    {
      id: '6',
      title: 'Knitted Zipper',
      price: 135,
      img: Image.product6,
    },
  ]
  return (
    <Wrapper>
      <Content>
        {/* //#region Header */}
        <Header>
          <InputWrapper>
            <SearchButton>
              <FaSearch />
            </SearchButton>
            <Input placeholder="Search" />
          </InputWrapper>
        </Header>
        {/* //#endregion */}

        {/* #region Product */}
        <ProductWrapper>
          {productList.map((val, idx) => {
            const isEven = idx % 2 === 0
            return (
              <Product
                onClick={() => {
                  navigate(`/products/${val.id}`)
                }}
                key={val.id}
                extraCss={isEven ? tw`mr-1` : tw`ml-1`}
              >
                <ProductImageWrapper>
                  <ProductImage src={val.img} />
                </ProductImageWrapper>
                <ProductTitle>{val.title}</ProductTitle>
                <ProductPrice>${val.price}</ProductPrice>
              </Product>
            )
          })}
        </ProductWrapper>
        {/* #endregion */}

        <Separator />
      </Content>
      {/* #region Bottom Bar */}

      <BottomBar>
        <BottomBarItem
          onClick={() => {
            navigate('/products')
          }}
        >
          <FaHome />
        </BottomBarItem>
        <BottomBarItem
          onClick={() => {
            navigate('/products/wishlist')
          }}
        >
          <FaHeart css={tw`text-orange-1`} />
        </BottomBarItem>
        <BottomBarItem
          onClick={() => {
            navigate('/order')
          }}
        >
          <FaCartArrowDown />
        </BottomBarItem>
        <BottomBarItem
          onClick={() => {
            navigate('/profile')
          }}
        >
          <FaUser />
        </BottomBarItem>
      </BottomBar>
      {/* #endregion */}
    </Wrapper>
  )
}

export default ProductWishList
