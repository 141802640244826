import styled from '@emotion/styled'
import React, { useState } from 'react'
import { FaCartPlus, FaChevronLeft, FaHeart } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import tw from 'twin.macro'
import Image from '../../../../assets/images'

//#region
const Wrapper = tw.div`max-w-md mx-auto h-full`
const Content = tw.div`w-full h-full`
const ProductImageWrapper = tw.div`height[480px] w-full relative`
const ProductButtonWrapper = tw.div`absolute flex flex-row justify-between w-full p-7`
const ProductButton = tw.div`bg-white-1 cursor-pointer w-10 h-10 flex items-center justify-center rounded-full`
const ProductImage = tw.img`w-full h-full object-cover rounded-b-3xl`

// product content
const ProductContent = tw.div`px-7 pt-4`
const ProductContentDetail = tw.div`flex flex-row justify-between items-center`
const ProductTitleWrapper = tw.div``
const ProductTitle = tw.div`text-lg font-bold`
const ProductSubtitle = tw.div`text-sm text-grey-AA font-bold`
const ProductPrice = tw.div`text-lg font-bold text-orange-1`
const ProductDescription = tw.div`text-sm mt-4 font-extralight text-grey-AA`
const Separator = tw.div`h-24`

// bottom button
const BottomButtonWrapper = tw.div`flex flex-row space-x-4 fixed bottom-0 left-0 right-0 py-4 px-7 bg-white-1 max-w-md mx-auto`
const CartButton = tw.button`h-16 w-16 rounded-full bg-white-2 flex items-center justify-center`
const BuyButton = tw.button`h-16 w-full rounded-full bg-orange-1 flex items-center justify-center text-white-1 font-bold flex-1`

// bottomsheet
const BottomSheetWrapper = tw.div`p-7`
const UtilityWrapper = tw.div``
const Utility = tw.div`flex flex-row mb-4`
const UtilityTitle = tw.div`w-16 font-bold`
const UtilityItem = tw.div`flex flex-row`
const UtilitySize = tw.div`w-6 h-6 border rounded mx-0.5 flex items-center justify-center text-xs`

const UtilityColor = styled.div(({ extraCss, isActive }) => [
  tw`w-6 h-6 border-2 rounded-full mx-0.5 bg-black-1`,
  extraCss,
  isActive && tw`border-orange-1`,
])

const CounterButton = tw.div`w-6 h-6 border rounded mx-0.5 flex items-center justify-center text-xs`
const CounterText = tw.div`font-bold mx-2`

const Divider = tw.div`border-t w-full border-grey-AA border-dashed`

const TotalWrapper = tw.div`flex flex-row justify-between my-4`
const TotalTitle = tw.div`font-bold`
const TotalPrice = tw.div`text-orange-1`

const CheckoutButton = tw.button`h-16 w-full bg-orange-1 flex items-center justify-center text-white-1 font-bold rounded-full`
//#endregion

const ProductDetail = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const { id } = useParams()
  return (
    <Wrapper>
      <Content>
        <ProductImageWrapper>
          <ProductButtonWrapper>
            <ProductButton
              onClick={() => {
                navigate(-1)
              }}
            >
              <FaChevronLeft />
            </ProductButton>
            <ProductButton>
              <FaHeart css={tw`text-red-1`} />
            </ProductButton>
          </ProductButtonWrapper>

          <ProductImage src={Image.product1} alt="product" title="product" />
        </ProductImageWrapper>

        <ProductContent>
          <ProductContentDetail>
            <ProductTitleWrapper>
              <ProductTitle>Warm Zipper {id}</ProductTitle>
              <ProductSubtitle>Hooded Jacket</ProductSubtitle>
            </ProductTitleWrapper>
            <ProductPrice>$120</ProductPrice>
          </ProductContentDetail>
          <ProductDescription>
            Cool, windy weather is on its way. Send him out the door in a jacket
            he wants to wear. Warm Zipper Hooded Jacket.
          </ProductDescription>
        </ProductContent>
        <Separator />
      </Content>
      <BottomButtonWrapper>
        <CartButton
          onClick={() => {
            setOpen(true)
          }}
        >
          <FaCartPlus css={tw`text-orange-1`} />
        </CartButton>
        <BuyButton
          onClick={() => {
            setOpen(true)
          }}
        >
          Buy Now
        </BuyButton>
      </BottomButtonWrapper>

      <BottomSheet
        open={open}
        onDismiss={() => {
          setOpen(false)
        }}
      >
        <BottomSheetWrapper>
          <UtilityWrapper>
            <Utility>
              <UtilityTitle>Size</UtilityTitle>
              <UtilityItem>
                <UtilitySize>S</UtilitySize>
                <UtilitySize>M</UtilitySize>
                <UtilitySize>L</UtilitySize>
                <UtilitySize>XL</UtilitySize>
              </UtilityItem>
            </Utility>

            <Utility>
              <UtilityTitle>Color</UtilityTitle>
              <UtilityItem>
                <UtilityColor isActive />
                <UtilityColor extraCss={tw`bg-green-600`} />
                <UtilityColor extraCss={tw`bg-yellow-1`} />
                <UtilityColor extraCss={tw`bg-grey-75`} />
              </UtilityItem>
            </Utility>

            <Utility>
              <UtilityTitle>Total</UtilityTitle>
              <UtilityItem>
                <CounterButton>-</CounterButton>
                <CounterText>1</CounterText>
                <CounterButton>+</CounterButton>
              </UtilityItem>
            </Utility>
          </UtilityWrapper>

          <Divider />

          <TotalWrapper>
            <TotalTitle>Total Payment</TotalTitle>
            <TotalPrice>$123</TotalPrice>
          </TotalWrapper>

          <CheckoutButton
            onClick={() => {
              navigate('/cart')
            }}
          >
            Checkout
          </CheckoutButton>
        </BottomSheetWrapper>
      </BottomSheet>
    </Wrapper>
  )
}

export default ProductDetail
