import React from 'react'
import { FaChevronLeft, FaMapPin } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'

const Wrapper = tw.div`max-w-md mx-auto h-full`
const Content = tw.div`w-full h-full p-7`

const Navigation = tw.div`flex flex-row justify-between items-center`
const NavigationButton = tw.button``
const NavigationTitle = tw.div``
const Main = tw.div`mt-4`

const BottomSection = tw.div`fixed bottom-0 left-0 right-0 max-w-md px-7 py-4 mx-auto bg-white-1`
const SaveButton = tw.button`h-16 w-full bg-orange-1 flex items-center justify-center rounded-full text-white-1 font-bold`

const BlankSpace = tw.div`h-20`

const Address = tw.div`flex flex-row items-center mb-4 bg-white-2 px-4 py-2 rounded-3xl`
const AddressIcon = tw.div`w-11 h-11 bg-grey-E5 rounded-full flex flex-row items-center justify-center`
const AddressContent = tw.div`flex-1 ml-2`
const AddressTitle = tw.div`text-xs font-bold text-black-1`
const AddressDescription = tw.div`text-xs text-grey-75`
const AddressRadio = tw.input``

const ButtonAdd = tw.button`h-16 w-full rounded-full bg-grey-AA flex flex-row items-center justify-center text-white-1 font-bold`

const AddressList = () => {
  const navigate = useNavigate()

  const addressList = [
    { title: 'Rumah', description: 'Lampung, Kotabumi' },
    { title: 'Kantor 1', description: 'Tulungrejo, Kediri, Jawa Timur' },
  ]

  return (
    <Wrapper>
      <Content>
        <Navigation>
          <NavigationButton
            onClick={() => {
              navigate(-1)
            }}
          >
            <FaChevronLeft />
          </NavigationButton>
          <NavigationTitle>Address</NavigationTitle>
          <div />
        </Navigation>

        <Main>
          {addressList.map((val, idx) => {
            return (
              <Address key={idx}>
                <AddressIcon>
                  <FaMapPin />
                </AddressIcon>
                <AddressContent>
                  <AddressTitle>{val.title}</AddressTitle>
                  <AddressDescription>{val.description}</AddressDescription>
                </AddressContent>
                <AddressRadio type="radio" name="address" value={idx} />
              </Address>
            )
          })}
        </Main>
        <ButtonAdd
          onClick={() => {
            navigate('/address/add')
          }}
        >
          Add New Address
        </ButtonAdd>
        <BlankSpace />
      </Content>
      <BottomSection>
        <SaveButton
          onClick={() => {
            navigate(-1)
          }}
        >
          Save
        </SaveButton>
      </BottomSection>
    </Wrapper>
  )
}

export default AddressList
