import React from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'

const Wrapper = tw.div`max-w-md mx-auto h-full`
const Content = tw.div`w-full h-full p-7`

const Navigation = tw.div`flex flex-row justify-between items-center`
const NavigationButton = tw.button``
const NavigationTitle = tw.div``
const Main = tw.div`mt-4`

const InputWrapper = tw.div`flex flex-col w-full mb-4`
const Label = tw.label`mb-3 ml-2.5 font-normal text-black-1`
const Input = tw.input`h-12 bg-white-2 rounded-2xl px-4`

const BottomSection = tw.div`fixed bottom-0 left-0 right-0 max-w-md px-7 py-4 mx-auto bg-white-1`
const SaveButton = tw.button`h-16 w-full bg-orange-1 flex items-center justify-center rounded-full text-white-1 font-bold`

const BlankSpace = tw.div`h-20`

const EditProfile = () => {
  const navigate = useNavigate()

  return (
    <Wrapper>
      <Content>
        <Navigation>
          <NavigationButton
            onClick={() => {
              navigate(-1)
            }}
          >
            <FaChevronLeft />
          </NavigationButton>
          <NavigationTitle>Edit Profile</NavigationTitle>
          <div />
        </Navigation>

        <Main>
          <InputWrapper>
            <Label htmlFor="email">Email</Label>
            <Input placeholder="Email" type="text" id="email" />
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="name">Nama</Label>
            <Input placeholder="Nama" type="text" id="name" />
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="phone">No HP</Label>
            <Input placeholder="No HP" type="text" id="phone" />
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="date">Tanggal Lahir</Label>
            <Input placeholder="Tanggal Lahir" type="date" id="date" />
          </InputWrapper>
        </Main>
        <BlankSpace />
      </Content>
      <BottomSection>
        <SaveButton
          onClick={() => {
            navigate(-1)
          }}
        >
          Save
        </SaveButton>
      </BottomSection>
    </Wrapper>
  )
}

export default EditProfile
