import React, { useState } from 'react'
import {
  FaChevronLeft,
  FaChevronRight,
  FaDollarSign,
  FaMapMarkerAlt,
  FaTruck,
} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { BottomSheet } from 'react-spring-bottom-sheet'
import tw from 'twin.macro'
import Image from '../../../../assets/images'

const Wrapper = tw.div`max-w-md mx-auto h-full`
const Content = tw.div`w-full h-full p-7`

const Navigation = tw.div`flex flex-row justify-between items-center`
const NavigationButton = tw.button``
const NavigationTitle = tw.div``
const ItemWrapper = tw.div`mt-4`

const BottomSection = tw.div`fixed bottom-0 left-0 right-0 max-w-md px-7 py-4 mx-auto bg-white-1`
const ContinueButton = tw.button`h-16 w-full bg-orange-1 flex items-center justify-center rounded-full text-white-1 font-bold`
const ConfirmButton = tw.button`h-16 w-full bg-orange-1 flex items-center justify-center rounded-full text-white-1 font-bold mt-4`

const BlankSpace = tw.div`h-24`
const CartItem = tw.div`flex flex-row items-center mb-6`

const CartImageWrapper = tw.div`w-20 h-20`
const CartImage = tw.img`w-full h-full object-cover rounded-2xl`
const CartContent = tw.div`flex flex-1 justify-between items-center`
const CartContentDetail = tw.div`ml-2`
const CartTitle = tw.div`text-xs font-bold text-black-1`
const CartCategory = tw.div`text-xs text-grey-75`
const CartPrice = tw.div`text-orange-1 font-extrabold text-sm`
const ItemCount = tw.div``
const ChooseItemWrapper = tw.div`mt-4`
const ChooseItemTitle = tw.div`font-bold mb-2`
const ChooseItem = tw.div`flex flex-row items-center bg-white-2 rounded-xl py-2 px-4 justify-between cursor-pointer`
const ChooseItemContent = tw.div`flex flex-row items-center`
const ChooseItemDescription = tw.div`ml-2`
const ItemTitle = tw.div`font-bold mb-2`

// bottomsheet
const BottomSheetWrapper = tw.div`p-7`
const BottomSheetContent = tw.div`flex flex-row justify-between`
const BottomSheetTitle = tw.div`text-black-1`
const BottomSheetPrice = tw.div``
const Divider = tw.div`border-t w-full border-grey-AA border-dashed my-4`

const Checkout = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  const cartList = [
    {
      id: '1',
      title: 'Warm Zipper',
      price: 300,
      img: Image.product1,
      category: 'Hoodie',
    },
    {
      id: '2',
      title: 'Knitterd Wool',
      price: 130,
      img: Image.product2,
      category: 'Hoodie',
    },
    {
      id: '3',
      title: 'Jeans Wool',
      price: 210,
      img: Image.product3,
      category: 'Hoodie',
    },
    {
      id: '4',
      title: 'Knitted Jeans',
      price: 160,
      img: Image.product4,
      category: 'Hoodie',
    },
    {
      id: '5',
      title: 'Warm Wool',
      price: 230,
      img: Image.product5,
      category: 'Hoodie',
    },
    {
      id: '6',
      title: 'Knitted Zipper',
      price: 135,
      img: Image.product6,
      category: 'Hoodie',
    },
  ]

  return (
    <Wrapper>
      <Content>
        <Navigation>
          <NavigationButton
            onClick={() => {
              navigate(-1)
            }}
          >
            <FaChevronLeft />
          </NavigationButton>
          <NavigationTitle>Checkout</NavigationTitle>
          <div />
        </Navigation>

        <ChooseItemWrapper>
          <ChooseItemTitle>Address</ChooseItemTitle>
          <ChooseItem
            onClick={() => {
              navigate('/address')
            }}
          >
            <ChooseItemContent>
              <FaMapMarkerAlt />
              <ChooseItemDescription>Choose Address</ChooseItemDescription>
            </ChooseItemContent>
            <FaChevronRight />
          </ChooseItem>
        </ChooseItemWrapper>

        <ItemWrapper>
          <ItemTitle>Items</ItemTitle>
          {cartList.map((val, idx) => {
            return (
              <CartItem key={idx}>
                <CartImageWrapper>
                  <CartImage src={val.img} alt={val.title} title={val.title} />
                </CartImageWrapper>
                <CartContent>
                  <CartContentDetail>
                    <CartTitle>{val.title}</CartTitle>
                    <CartCategory>{val.category}</CartCategory>
                    <CartPrice>{val.price}</CartPrice>
                  </CartContentDetail>
                </CartContent>
                <ItemCount>x1</ItemCount>
              </CartItem>
            )
          })}
        </ItemWrapper>

        <ChooseItemWrapper>
          <ChooseItemTitle>Shipping</ChooseItemTitle>
          <ChooseItem
            onClick={() => {
              navigate('/shipping')
            }}
          >
            <ChooseItemContent>
              <FaTruck />
              <ChooseItemDescription>Choose Shipping</ChooseItemDescription>
            </ChooseItemContent>
            <FaChevronRight />
          </ChooseItem>
        </ChooseItemWrapper>

        <ChooseItemWrapper>
          <ChooseItemTitle>Payment</ChooseItemTitle>
          <ChooseItem
            onClick={() => {
              navigate('/payment')
            }}
          >
            <ChooseItemContent>
              <FaDollarSign />
              <ChooseItemDescription>Choose Payment</ChooseItemDescription>
            </ChooseItemContent>
            <FaChevronRight />
          </ChooseItem>
        </ChooseItemWrapper>

        <BlankSpace />
      </Content>
      <BottomSection>
        <ContinueButton
          onClick={() => {
            setOpen(true)
          }}
        >
          Continue
        </ContinueButton>
      </BottomSection>

      <BottomSheet
        open={open}
        onDismiss={() => {
          setOpen(false)
        }}
      >
        <BottomSheetWrapper>
          <BottomSheetContent>
            <BottomSheetTitle>Subtotal for product</BottomSheetTitle>
            <BottomSheetPrice>$123</BottomSheetPrice>
          </BottomSheetContent>

          <BottomSheetContent>
            <BottomSheetTitle>Delivery service</BottomSheetTitle>
            <BottomSheetPrice>$12</BottomSheetPrice>
          </BottomSheetContent>

          <Divider />

          <BottomSheetContent>
            <BottomSheetTitle>Total Payment</BottomSheetTitle>
            <BottomSheetPrice>$135</BottomSheetPrice>
          </BottomSheetContent>

          <ConfirmButton
            onClick={() => {
              navigate('/order-success')
            }}
          >
            Confirm
          </ConfirmButton>
        </BottomSheetWrapper>
      </BottomSheet>
    </Wrapper>
  )
}

export default Checkout
