import { css, Global } from '@emotion/react'
import React from 'react'
import tw, { GlobalStyles as BaseStyles } from 'twin.macro'
import myFontUrl from '../assets/font/AirbnbCereal_W_Lt.otf'

const customStyles = css`
  @font-face {
    font-family: 'Airbnb Cereal';
    src: url(${myFontUrl}) format('woff2');
  }
  body {
    ${tw`antialiased`}
    height: 100%;
    font-family: 'Airbnb Cereal';
  }
  html {
    height: 100%;
  }
  #root {
    height: 100%;
  }
  :root {
    --rsbs-max-w: 28rem;
    --rsbs-ml: auto;
    --rsbs-mr: auto;
  }
`

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <Global styles={customStyles} />
  </>
)

export default GlobalStyles
