import React from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'
import Image from '../../../../assets/images'

const Wrapper = tw.div`max-w-md mx-auto h-full`
const Content = tw.div`w-full h-full p-7`

const Navigation = tw.div`flex flex-row justify-between items-center`
const NavigationButton = tw.button``
const NavigationTitle = tw.div``
const Main = tw.div`mt-4`

const BottomSection = tw.div`fixed bottom-0 left-0 right-0 max-w-md px-7 py-4 mx-auto bg-white-1`
const SaveButton = tw.button`h-16 w-full bg-orange-1 flex items-center justify-center rounded-full text-white-1 font-bold`

const BlankSpace = tw.div`h-48`
const CartItem = tw.div`flex flex-row items-center mb-6`

const Checkbox = tw.input`w-5 h-5 mr-4`

const CartImageWrapper = tw.div`w-20 h-20`
const CartImage = tw.img`w-full h-full object-cover rounded-2xl`
const CartContent = tw.div`flex flex-1 justify-between items-center`
const CartContentDetail = tw.div`ml-2`
const CartTitle = tw.div`text-xs font-bold text-black-1`
const CartCategory = tw.div`text-xs text-grey-75`
const CartPrice = tw.div`text-orange-1 font-extrabold text-sm`
const CounterButton = tw.div`w-6 h-6 border rounded mx-0.5 flex items-center justify-center text-xs`
const CounterText = tw.div`font-bold mx-2`
const UtilityItem = tw.div`flex flex-row`

const SelectAllWrapper = tw.div`flex flex-row justify-between`
const SelectAllTitle = tw.div``
const SelectAll = tw.input`w-5 h-5`
const Divider = tw.div`border-t w-full border-grey-AA border-dashed mt-4`

const TotalWrapper = tw.div`flex flex-row justify-between my-4`
const TotalTitle = tw.div`text-black-1`
const TotalPrice = tw.div`text-orange-1`

const Cart = () => {
  const navigate = useNavigate()

  const cartList = [
    {
      id: '1',
      title: 'Warm Zipper',
      price: 300,
      img: Image.product1,
      category: 'Hoodie',
    },
    {
      id: '2',
      title: 'Knitterd Wool',
      price: 130,
      img: Image.product2,
      category: 'Hoodie',
    },
    {
      id: '3',
      title: 'Jeans Wool',
      price: 210,
      img: Image.product3,
      category: 'Hoodie',
    },
    {
      id: '4',
      title: 'Knitted Jeans',
      price: 160,
      img: Image.product4,
      category: 'Hoodie',
    },
    {
      id: '5',
      title: 'Warm Wool',
      price: 230,
      img: Image.product5,
      category: 'Hoodie',
    },
    {
      id: '6',
      title: 'Knitted Zipper',
      price: 135,
      img: Image.product6,
      category: 'Hoodie',
    },
  ]

  return (
    <Wrapper>
      <Content>
        <Navigation>
          <NavigationButton
            onClick={() => {
              navigate(-1)
            }}
          >
            <FaChevronLeft />
          </NavigationButton>
          <NavigationTitle>Cart</NavigationTitle>
          <div />
        </Navigation>

        <Main>
          {cartList.map((val, idx) => {
            return (
              <CartItem key={idx}>
                <Checkbox type="checkbox" />
                <CartImageWrapper>
                  <CartImage src={val.img} alt={val.title} title={val.title} />
                </CartImageWrapper>
                <CartContent>
                  <CartContentDetail>
                    <CartTitle>{val.title}</CartTitle>
                    <CartCategory>{val.category}</CartCategory>
                    <CartPrice>{val.price}</CartPrice>
                  </CartContentDetail>
                </CartContent>
                <UtilityItem>
                  <CounterButton>-</CounterButton>
                  <CounterText>1</CounterText>
                  <CounterButton>+</CounterButton>
                </UtilityItem>
              </CartItem>
            )
          })}
        </Main>

        <BlankSpace />
      </Content>
      <BottomSection>
        <SelectAllWrapper>
          <SelectAllTitle>Select all</SelectAllTitle>
          <SelectAll type="checkbox" />
        </SelectAllWrapper>

        <Divider />

        <TotalWrapper>
          <TotalTitle>Total Payment</TotalTitle>
          <TotalPrice>$123</TotalPrice>
        </TotalWrapper>

        <SaveButton
          onClick={() => {
            navigate('/checkout')
          }}
        >
          Checkout
        </SaveButton>
      </BottomSection>
    </Wrapper>
  )
}

export default Cart
