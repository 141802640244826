import React from 'react'
import { FaChevronDown, FaChevronLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'

const Wrapper = tw.div`max-w-md mx-auto h-full`
const Content = tw.div`w-full h-full p-7`

const Navigation = tw.div`flex flex-row justify-between items-center`
const NavigationButton = tw.button``
const NavigationTitle = tw.div``
const Main = tw.div`mt-4`

const InputWrapper = tw.div`flex flex-col w-full mb-4`
const Label = tw.label`mb-3 ml-2.5 font-normal text-black-1`
const Input = tw.input`h-12 bg-white-2 rounded-2xl px-4`

const BottomSection = tw.div`fixed bottom-0 left-0 right-0 max-w-md px-7 py-4 mx-auto bg-white-1`
const SaveButton = tw.button`h-16 w-full bg-orange-1 flex items-center justify-center rounded-full text-white-1 font-bold`

const BlankSpace = tw.div`h-20`

const SelectWrapper = tw.div`h-12 bg-white-2 rounded-2xl relative`
const SelectIcon = tw.div`absolute right-0 h-12 w-12 flex items-center justify-center`
const Select = tw.select`w-full h-12 absolute px-4 left-0 right-0 bg-transparent rounded-2xl appearance-none`
const Option = tw.option``
const TextArea = tw.textarea`h-24 rounded-2xl p-4 bg-white-2`

const AddNewAddress = () => {
  const navigate = useNavigate()
  const provinceList = [
    { id: 1, title: 'Lampung' },
    { id: 2, title: 'Sumatera Selatan' },
    { id: 3, title: 'Jawa Timur' },
  ]

  const kabupatenList = [
    { id: 1, title: 'Lampung Utara' },
    { id: 2, title: 'Lampung Selatan' },
    { id: 3, title: 'Lampung Timur' },
  ]

  const kecamatanList = [
    { id: 1, title: 'Abung Surakarta' },
    { id: 2, title: 'Abung Semuli' },
    { id: 3, title: 'Semuli Raya' },
  ]

  const desaList = [
    { id: 1, title: 'Bandar Sakti' },
    { id: 2, title: 'Tatakarya' },
    { id: 3, title: 'Purbasakti' },
  ]

  return (
    <Wrapper>
      <Content>
        <Navigation>
          <NavigationButton
            onClick={() => {
              navigate(-1)
            }}
          >
            <FaChevronLeft />
          </NavigationButton>
          <NavigationTitle>Add New Address</NavigationTitle>
          <div />
        </Navigation>

        <Main>
          <InputWrapper>
            <Label htmlFor="label">Label</Label>
            <Input placeholder="Label" type="text" id="label" />
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="name">Nama Penerima</Label>
            <Input placeholder="Nama Penerima" type="text" id="name" />
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="phone">No HP</Label>
            <Input placeholder="No HP" type="text" id="phone" />
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="province">Provinsi</Label>
            <SelectWrapper>
              <SelectIcon>
                <FaChevronDown />
              </SelectIcon>
              <Select id="province">
                {provinceList.map((val, idx) => {
                  return (
                    <Option key={idx} value={val.id}>
                      {val.title}
                    </Option>
                  )
                })}
              </Select>
            </SelectWrapper>
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="kabupaten">Kabupaten</Label>
            <SelectWrapper>
              <SelectIcon>
                <FaChevronDown />
              </SelectIcon>
              <Select id="kabupaten">
                {provinceList.map((val, idx) => {
                  return (
                    <Option key={idx} value={val.id}>
                      {val.title}
                    </Option>
                  )
                })}
              </Select>
            </SelectWrapper>
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="kecamatan">Kecamatan</Label>
            <SelectWrapper>
              <SelectIcon>
                <FaChevronDown />
              </SelectIcon>
              <Select id="kecamatan">
                {provinceList.map((val, idx) => {
                  return (
                    <Option key={idx} value={val.id}>
                      {val.title}
                    </Option>
                  )
                })}
              </Select>
            </SelectWrapper>
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="desa">Desa</Label>
            <SelectWrapper>
              <SelectIcon>
                <FaChevronDown />
              </SelectIcon>
              <Select id="desa">
                {provinceList.map((val, idx) => {
                  return (
                    <Option key={idx} value={val.id}>
                      {val.title}
                    </Option>
                  )
                })}
              </Select>
            </SelectWrapper>
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="description">Alamat Lengkap</Label>
            <TextArea placeholder="Alamat Lengkap" id="description" />
          </InputWrapper>
        </Main>
        <BlankSpace />
      </Content>
      <BottomSection>
        <SaveButton
          onClick={() => {
            navigate(-1)
          }}
        >
          Save
        </SaveButton>
      </BottomSection>
    </Wrapper>
  )
}

export default AddNewAddress
