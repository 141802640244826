import React from 'react'
import { FaChevronLeft, FaTruck } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'

const Wrapper = tw.div`max-w-md mx-auto h-full`
const Content = tw.div`w-full h-full p-7`

const Navigation = tw.div`flex flex-row justify-between items-center`
const NavigationButton = tw.button``
const NavigationTitle = tw.div``
const Main = tw.div`mt-4`

const BottomSection = tw.div`fixed bottom-0 left-0 right-0 max-w-md px-7 py-4 mx-auto bg-white-1`
const SaveButton = tw.button`h-16 w-full bg-orange-1 flex items-center justify-center rounded-full text-white-1 font-bold`

const BlankSpace = tw.div`h-20`

const Shipping = tw.div`flex flex-row items-center mb-4 bg-white-2 px-4 py-2 rounded-3xl`
const ShippingIcon = tw.div`w-11 h-11 bg-grey-E5 rounded-full flex flex-row items-center justify-center`
const ShippingContent = tw.div`flex-1 ml-2`
const ShippingTitle = tw.div`text-xs font-bold text-black-1`
const ShippingDescription = tw.div`text-xs text-grey-75`
const ShippingRadio = tw.input``
const ShippingPriceWrapper = tw.div`flex flex-row`
const ShippingPrice = tw.div`font-bold text-xs mr-2`

const ShippingList = () => {
  const navigate = useNavigate()

  const shippingList = [
    { title: 'Reguler', description: 'Estimated arrival, 22-26', price: 10 },
    {
      title: 'Express',
      description: 'Estimated arrival, 20-23',
      price: 12,
    },
  ]

  return (
    <Wrapper>
      <Content>
        <Navigation>
          <NavigationButton
            onClick={() => {
              navigate(-1)
            }}
          >
            <FaChevronLeft />
          </NavigationButton>
          <NavigationTitle>Shipping</NavigationTitle>
          <div />
        </Navigation>

        <Main>
          {shippingList.map((val, idx) => {
            return (
              <Shipping key={idx}>
                <ShippingIcon>
                  <FaTruck />
                </ShippingIcon>
                <ShippingContent>
                  <ShippingTitle>{val.title}</ShippingTitle>
                  <ShippingDescription>{val.description}</ShippingDescription>
                </ShippingContent>

                <ShippingPriceWrapper>
                  <ShippingPrice>${val.price}</ShippingPrice>
                  <ShippingRadio type="radio" name="shipping" value={idx} />
                </ShippingPriceWrapper>
              </Shipping>
            )
          })}
        </Main>

        <BlankSpace />
      </Content>
      <BottomSection>
        <SaveButton
          onClick={() => {
            navigate(-1)
          }}
        >
          Save
        </SaveButton>
      </BottomSection>
    </Wrapper>
  )
}

export default ShippingList
