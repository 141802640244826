import banner from './banner.png'
import logo from './logo.png'
import note from './note.png'
import product1 from './product1.png'
import product2 from './product2.png'
import product3 from './product3.png'
import product4 from './product4.png'
import product5 from './product5.png'
import product6 from './product6.png'
import profile from './profile.png'

const Image = {
  banner,
  product1,
  product2,
  product3,
  product4,
  product5,
  product6,
  profile,
  note,
  logo,
}

export default Image
