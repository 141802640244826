import React from 'react'
import {
  FaCartArrowDown,
  FaChevronDown,
  FaChevronLeft,
  FaHeart,
  FaHome,
  FaUser,
} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'
import Image from '../../../../assets/images'

const Wrapper = tw.div`max-w-md mx-auto h-full`
const Content = tw.div`w-full h-full p-7`

const Navigation = tw.div`flex flex-row justify-between items-center`
const NavigationButton = tw.button``
const NavigationTitle = tw.div``
const ItemWrapper = tw.div`mt-4`

const BlankSpace = tw.div`h-24`
const CartItem = tw.div`flex flex-row items-center mb-6`

const CartImageWrapper = tw.div`w-20 h-20`
const CartImage = tw.img`w-full h-full object-cover rounded-2xl`
const CartContent = tw.div`flex flex-1 justify-between items-center`
const CartContentDetail = tw.div`ml-2`
const CartTitle = tw.div`text-xs font-bold text-black-1`
const CartCategory = tw.div`text-xs text-grey-75`
const CartPrice = tw.div`text-orange-1 font-extrabold text-sm`
const StatusWrapper = tw.div`flex flex-row items-start`
const Status = tw.div`text-xs text-white-1 bg-grey-C5 font-extrabold rounded inline p-1`
const CartItemDetail = tw.div`flex justify-between flex-1`

const BottomBar = tw.div`bg-white-1 text-grey-75 flex flex-row justify-between items-center h-20 rounded-t-3xl fixed bottom-0 left-0 right-0 shadow-inner max-w-md px-7 mx-auto`
const BottomBarItem = tw.div`cursor-pointer w-10 flex items-center justify-center`

const InputWrapper = tw.div`flex flex-col w-full my-4`
const SelectWrapper = tw.div`h-12 bg-white-2 rounded-2xl relative`
const SelectIcon = tw.div`absolute right-0 h-12 w-12 flex items-center justify-center`
const Select = tw.select`w-full h-12 absolute px-4 left-0 right-0 bg-transparent rounded-2xl appearance-none`
const Option = tw.option``

const Order = () => {
  const navigate = useNavigate()

  const cartList = [
    {
      id: '1',
      title: 'Warm Zipper',
      price: 300,
      img: Image.product1,
      category: 'Hoodie',
    },
    {
      id: '2',
      title: 'Knitterd Wool',
      price: 130,
      img: Image.product2,
      category: 'Hoodie',
    },
    {
      id: '3',
      title: 'Jeans Wool',
      price: 210,
      img: Image.product3,
      category: 'Hoodie',
    },
    {
      id: '4',
      title: 'Knitted Jeans',
      price: 160,
      img: Image.product4,
      category: 'Hoodie',
    },
    {
      id: '5',
      title: 'Warm Wool',
      price: 230,
      img: Image.product5,
      category: 'Hoodie',
    },
    {
      id: '6',
      title: 'Knitted Zipper',
      price: 135,
      img: Image.product6,
      category: 'Hoodie',
    },
  ]

  const statusList = [
    {
      id: 1,
      title: 'In Delivery',
    },
  ]

  return (
    <Wrapper>
      <Content>
        <Navigation>
          <NavigationButton
            onClick={() => {
              navigate(-1)
            }}
          >
            <FaChevronLeft />
          </NavigationButton>
          <NavigationTitle>Order</NavigationTitle>
          <div />
        </Navigation>

        <InputWrapper>
          <SelectWrapper>
            <SelectIcon>
              <FaChevronDown />
            </SelectIcon>
            <Select id="province">
              {statusList.map((val, idx) => {
                return (
                  <Option key={idx} value={val.id}>
                    {val.title}
                  </Option>
                )
              })}
            </Select>
          </SelectWrapper>
        </InputWrapper>

        <ItemWrapper>
          {cartList.map((val, idx) => {
            return (
              <CartItem key={idx}>
                <CartImageWrapper>
                  <CartImage src={val.img} alt={val.title} title={val.title} />
                </CartImageWrapper>
                <CartItemDetail>
                  <CartContent>
                    <CartContentDetail>
                      <CartTitle>{val.title}</CartTitle>
                      <CartCategory>{val.category}</CartCategory>
                      <CartPrice>{val.price}</CartPrice>
                    </CartContentDetail>
                  </CartContent>
                  <StatusWrapper>
                    <Status>In Delivery</Status>
                  </StatusWrapper>
                </CartItemDetail>
              </CartItem>
            )
          })}
        </ItemWrapper>

        <BlankSpace />
      </Content>
      {/* #region Bottom Bar */}

      <BottomBar>
        <BottomBarItem
          onClick={() => {
            navigate('/products')
          }}
        >
          <FaHome />
        </BottomBarItem>
        <BottomBarItem
          onClick={() => {
            navigate('/products/wishlist')
          }}
        >
          <FaHeart />
        </BottomBarItem>
        <BottomBarItem
          onClick={() => {
            navigate('/order')
          }}
          css={tw`text-orange-1`}
        >
          <FaCartArrowDown />
        </BottomBarItem>
        <BottomBarItem
          onClick={() => {
            navigate('/profile')
          }}
        >
          <FaUser />
        </BottomBarItem>
      </BottomBar>
      {/* #endregion */}
    </Wrapper>
  )
}

export default Order
